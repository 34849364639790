import React from "react";

export default function Button({ children, className, ...rest }) {
  return (
    <button
      {...rest}
      className={`${className} py-1 px-2 bg-black dark:bg-white text-white dark:text-black rounded-sm border-2 border-solid border-black dark:hover:border-white dark:hover:bg-black hover:bg-white dark:hover:text-white hover:text-black`}
    >
      {children}
    </button>
  );
}
