import { Link } from "gatsby";
import moment from "moment";
import React from "react";

import Button from "../components/button";
import ClientOnly from "../components/client-only";
import ImageEnlarger from "../components/image-enlarger";
import SEO from "../components/seo";
import image1 from "../images/home.jpeg";
import image2 from "../images/home-1.png";
import image3 from "../images/home-2.png";

function Logo() {
  return (
    <Link className="text-base font-heading tracking-widest" to="/">
      periodical
    </Link>
  );
}

export default function IndexPage() {
  const mainStyle = { minHeight: "calc(100vh - 133px)" };
  return (
    <ClientOnly>
      <SEO title="Get Periodical" />
      <div className="p-6 md:p-0 flex flex-col items-center min-h-screen w-full overflow-x-hidden font-body">
        <div className="flex flex-col items-center max-w-screen-md w-full">
          <div className="pt-12 pb-4 text-5xl font-heading">periodical</div>
          <div className="flex text-center flex-row justify-center pb-3 text-lg font-heading border-double border-b-2 border-black w-full">
            Like Goodreads and The New York Times had a baby
          </div>
          <div className="flex flex-row justify-center items-center py-3 text-base font-heading border-double border-b-2 border-black w-full">
            {moment().format("LL")}
            <span className="mx-4">|</span>
            Established 2021
          </div>
          <Link className="py-8" to="/app">
            <Button data-splitbee-event="Go to Login From Top Button" className="px-3 py-2 ring ring-black ring-offset-2">
              Start reading the web with class. It's free.
            </Button>
          </Link>
        </div>
        <main
          className="flex flex-col items-center max-w-screen-md w-full px-2 overflow-x-hidden border-solid border-2 border-black"
          style={mainStyle}
        >
          <div className="py-8 flex flex-col items-center w-full">
            <div className="flex flex-row justify-center pb-8">
              <Link to="/app">
                <h1 className="text-5xl md:text-7xl text-center font-bold font-heading cursor-pointer hover:underline">
                  Organize web articles into a newspaper and track your progress
                </h1>
              </Link>
            </div>
            <div className="flex flex-row justify-center">
              <ImageEnlarger
                className="shadow-sm object-cover object-center h-auto w-full"
                alt="periodical"
                src={image1}
              />
            </div>
          </div>
          <div className="flex flex-row flex-wrap justify-center w-full py-4">
            <div className="flex flex-col items-center w-full md:w-1/2 px-6 text-justify">
              <p>
                We get it. You like the organization that Goodreads provides, but
                you're not always too crazy about the visual experience; plus, you
                spend just as much time reading articles as you do books.
              </p>
              <p className="my-8">
                Don't worry. We've got you covered. You can now collect, organize,
                and track the progress of articles you find on the web.
              </p>
              <p>
                As for the visual experience, we're minimalistic and fancy...a
                subtle fusion of modernity and tradition.
              </p>
              <p className="my-8">
                We miss the good ole days that had less distraction. Like when
                memaw and pepaw would get all the news from a paper on their
                kitchen table. But thankfully, there's a lot more stuff to
                discover on the interwebs.
              </p>
              <p>
                Now, you can get the best of the past and present. Aggregate that
                good stuff you find on the internet, and we'll display it as a
                newspaper.
              </p>
            </div>
            <div className="flex flex-col items-center justify-around mt-8 md:mt-0 w-full md:w-1/2 px-6 text-justify">
              <div className="flex flex-row justify-center">
                <ImageEnlarger
                  className="shadow-sm ring ring-black ring-offset-2 border-solid border-2 border-black object-cover object-center h-auto w-full"
                  alt="periodical"
                  src={image2}
                />
              </div>
              <div className="flex-row justify-center hidden md:flex">
                <ImageEnlarger
                  className="shadow-sm ring ring-black ring-offset-2 border-solid border-2 border-black object-cover object-center h-auto w-full"
                  alt="periodical"
                  src={image3}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center w-full">
            <Link className="py-8" to="/app">
              <Button data-splitbee-event="Go to Login From Bottom Button" className="px-3 py-2 ring ring-black ring-offset-2">
                Start reading the web with class. It's free.
              </Button>
            </Link>
          </div>
        </main>
        <footer className="p-8 w-full flex items-end justify-end justify-self-end max-w-screen-lg">
          <Logo />
        </footer>
      </div>
    </ClientOnly>
  );
}
